'use strict';

angular.module('cpformplastApp.items')
    .factory('ItemEfficiencyReport', function() {

    class ItemEfficiencyReport {
        constructor({id, createdAt, sheetsPerHour, machineId}) {
          this.id = id;
          this.createdAt = new Date(createdAt);
          this.sheetsPerHour = sheetsPerHour;
          this.machineId = machineId
        }
    }

    return ItemEfficiencyReport;
});
